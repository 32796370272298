import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { PlanRes } from "../api/types/plan";
import authState from "../recoil/auth";

export default function AboutPage() {
  const [{ account }] = useRecoilState(authState);

  const [planRes, setPlanRes] = useState<PlanRes>();

  useEffect(() => {
    setPlanRes(account?.plan);
  }, []);

  return (
    <main className="py-14 about-page bg-gray-50">
      <section className="sec1">
        <div className="container flex gap-5 mx-auto justify-center">
          <p className="shadow-sm bg-white">
            온라인 비지니스를
            <br />
            시작하고자 하는 대표님
          </p>
          <p className="shadow-sm bg-white">
            매출이 나오지 않아서
            <br />
            고민이신 대표님
          </p>
          <p className="shadow-sm bg-white">
            나만의 브랜드 제품을
            <br />
            만들고 싶은 대표님
          </p>
        </div>
      </section>
      <section className="sec2">
        <div className="container mx-auto mt-8 px-4" style={{ maxWidth: 640 }}>
          <p>위 세 분의 대표님의 고민은 하나의 의문에서 출발되었습니다.</p>
          <br />
          <p className="font-semibold">"무엇을 팔 것인가?"</p>
          <br />
          <p>블루오션을 남들보다 먼저, 남들보다 빠르게 선점하는 것</p>
          <br />

          <p>그러한 과정을 빠르고 율적으로 분석하여 진행하는 것</p>
          <br />
          <p>베스트소싱 하나로 해결할 수 있습니다.</p>
          <br />
          <p>
            특정 플랫폼에 국한된 정보가 아닌 다양한 플랫폼의 트렌드를 실시간으로 분석하여 제품 추천을 해줄 뿐 아니라,
            트렌드를 예측하여 정보를 제공합니다.
          </p>
          <br />
          <p>
            상품 추천뿐만 아니라 눈에 띄는 매출 상승을 위한 정보도 제공하여 나만의 브랜딩 제품을 키워나가는 과정까지
            필요한 정보를 제공합니다.
          </p>
          <br />
          <p>이제 베스트 소싱과 함께 대표님의 성공적인 온라인 비지니스를 시작하세요.</p>
        </div>
      </section>
    </main>
  );
}
