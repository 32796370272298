import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../api";

export default function Index() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const paymentType = searchParams.get("paymentType") || "";
    const orderId = searchParams.get("orderId") || "";
    const paymentKey = searchParams.get("paymentKey") || "";
    const amount = searchParams.get("amount") || "";

    api.toss
      .success(paymentType, orderId, paymentKey, amount)
      .then(() => {
        navigate("/my");
      })
      .catch((e) => {
        alert("네트워크 에러");
      });
  }, []);

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8"></div>
    </main>
  );
}
