import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import authState from "../recoil/auth";
import api from "../api";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function SignUp() {
  const navigate = useNavigate();

  const setAuthState = useSetRecoilState(authState);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [pending, setPending] = useState(false);

  async function submit(e: any) {
    e.preventDefault();
    setPending(true);
    try {
      const email = e.target.email.value;
      const password = e.target.password.value;
      const response = await api.account.create(email, password);

      setErrorMessage("");
      setAuthState({
        account: response.data,
        waiting: false,
      });
      navigate("/");
    } catch (e) {
      setErrorMessage("아이디 비밀번호를 확인해주세요");
    } finally {
      setPending(false);
    }
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-gray-50 p-2">
      <div className="z-10 w-full max-w-md overflow-hidden rounded-2xl border border-gray-100 shadow-xl">
        <div className="flex flex-col items-center justify-center space-y-3 border-b border-gray-200 bg-white px-4 py-6 pt-8 text-center sm:px-16">
          <h3 className="text-xl font-semibold">회원가입</h3>
        </div>
        <form onSubmit={submit} className="flex flex-col space-y-4 bg-gray-50 px-4 py-8 sm:px-16">
          <div>
            <label htmlFor="email" className="block text-xs text-gray-600 uppercase">
              이메일
            </label>
            <input
              id="email"
              name="email"
              type="email"
              // placeholder="이메일 주소를 입력해주세요"
              autoComplete="email"
              required
              // value={email}
              className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-xs text-gray-600 uppercase">
              비밀번호
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              // value={password}
              className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
            />
          </div>
          {errorMessage && (
            <p className="flex gap-1">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
              <span className="text-sm text-red-500">{errorMessage}</span>
            </p>
          )}
          <button
            type={pending ? "button" : "submit"}
            aria-disabled={pending}
            className="flex h-10 w-full items-center justify-center rounded-md border text-sm transition-all focus:outline-none hover:bg-gray-100"
          >
            가입하기
            {pending && (
              <svg
                className="animate-spin ml-2 h-4 w-4 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
              </svg>
            )}
            <span aria-live="polite" className="sr-only" role="status">
              {pending ? "Loading" : "Submit form"}
            </span>
          </button>
          <p className="text-center text-sm text-gray-600">
            {"이미 계정이 있나요? "}
            <Link to="/login" className="font-semibold text-gray-800">
              로그인
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}
