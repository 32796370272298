import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../api";

export default function Index() {
  const [errorCode, setErrorCode] = useState("");
  const [message, setMessage] = useState("");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code") || "";
    const message = searchParams.get("message") || "";
    const orderId = searchParams.get("orderId") || "";

    api.toss.fail(code, message, orderId);

    setErrorCode(code);
    setMessage(message);
  }, []);

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8">
        <div className="bg-white p-4 bg-white rounded shadow">
          <div className="text-center" style={{ padding: 24 }}>
            <h1 className="mt-30 font-semibold">결제 실패({errorCode})</h1>
            <p className="mt-6">{message}</p>
          </div>
        </div>
      </div>
    </main>
  );
}
