import request from "../utils/Request";

const aboutApi = {
  // 내 정보
  fetch: (type: string) => {
    return request.get("/v1/text?type=" + type);
  },
};

export default aboutApi;
