import request from "../utils/Request";

const managerApi = {
  // 유저 리스트
  fetchUsers: (uid?: string, page: number = 0) => {
    return request.get(`/v1/manager/user`, { params: { uid, page } });
  },
  // 유저 상세
  fetchUser: (uid: string) => {
    return request.get(`/v1/manager/user/${uid}`);
  },
  // UID 갱신
  updateUserPlan: (userId: number, planId: number, days: number, price: number = 28000) => {
    return request.post(`/v1/manager/user/${userId}/plan/${planId}?days=${days}&price=${price}`);
  },
};

export default managerApi;
