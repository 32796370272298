import { XCircleIcon, TrashIcon, ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import api from "../api";
import { MarketType } from "../api/types/market";

type Props = {
  onClose: () => void;
};

export default function ManageModalPopup({ onClose }: Props) {
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {}, []);

  async function submit(e: any) {
    e.preventDefault();

    const oldPassword = e.target.oldPassword.value;
    const password = e.target.password.value;
    const passwordConfirm = e.target.passwordConfirm.value;
    if (!password) {
      setErrorMessage("비밀번호를 입력해주세요");
      return;
    }
    if (password !== passwordConfirm) {
      setErrorMessage("비밀번호가 일치하지 않습니다");
      return;
    }

    api.account
      .updatePassword(oldPassword, password)
      .then(() => {
        onClose();
      })
      .catch(() => {
        setErrorMessage("이전 비밀번호를 확인해주세요");
      });

    return false;
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full h-screen max-h-full bg-black bg-opacity-70">
      <div className="bg-gray-50 max-w-sm w-full p-4 rounded shadow relative mx-auto top-10">
        <div>
          <h3 className="text-xl font-semibold text-gray-700">비밀번호 수정</h3>
          <button
            type="button"
            className="absolute top-4 right-4 "
            onClick={() => {
              onClose();
            }}
          >
            <XCircleIcon color="black" className="w-8 h-8" />
          </button>
        </div>
        <div className="mt-4">
          <form onSubmit={submit} className="w-full flex flex-col align-center justify-center">
            <div>
              <label htmlFor="password" className="block text-xs text-gray-600 uppercase">
                이전 비밀번호
              </label>
              <input
                id="oldPassword"
                name="oldPassword"
                type="password"
                required
                // value={password}
                className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
              />
              <label htmlFor="password" className="block text-xs text-gray-600 uppercase">
                새 비밀번호
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                // value={password}
                className="mt-1 block w-full appearance-none rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
              />
              <label htmlFor="password" className="block text-xs text-gray-600 uppercase">
                비밀번호 확인
              </label>
              <input
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                required
                // value={password}
                className="mt-1 w-full appearance-none rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
              />
              {errorMessage && <p className="mt-2 flex gap-1 text-xs text-red-500">{errorMessage}</p>}
              <div className="flex gap-2 justify-center">
                <button className="mt-4 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600" type="submit">
                  수정완료
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
