import request from "../utils/Request";
import { MarketType } from "./types/market";

const marketApi = {
  // 내 관심 마켓
  fetchMarkets: (marketType: MarketType = "naver") => {
    return request.get(`/v1/${marketType}/my`);
  },
  createMarket: (marketType: MarketType, list: string[]) => {
    return request.post(`/v1/${marketType}/my`, list);
  },
  requestRefresh: (marketType: MarketType) => {
    return request.post(`/v1/${marketType}/my/best/refresh`);
  },
  fetchBests: (marketType: MarketType, page: number, size: number) => {
    return request.get(`/v1/${marketType}/my/best`, { params: { page, size } });
  },
};

export default marketApi;
