import { PlanRes } from "./plan";

export type Account = {
  id: number;
  uid: string;
  username: string;
  plan: PlanRes;
  authorities: AuthItem[];
  isAdminPageAccessible: boolean;
  isCsvExport: boolean;
};

export type AuthItem = {
  name: string;
};

export type User = {
  id?: string;
  name?: string;
  email?: string;
  password?: string;
};

export const USER_ROLES = ["ROLE_SYSTEM_ADMIN", "ROLE_ADMIN", "ROLE_DEVELOPER", "ROLE_MANAGER"];
