import { XCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import api from "../api";
import { MarketType } from "../api/types/market";

type Props = {
  marketType: MarketType;
  onUpdate: () => void;
  onClose: () => void;
};

export default function ManageModalPopup({ onClose, onUpdate, marketType }: Props) {
  const [pending, setPending] = useState(false);
  const [marketString, setMarketString] = useState("");

  useEffect(() => {
    fetchMyMarket();

    const document = (window as any).document;
    document.querySelector("body").style.overflow = "hidden";
    return () => {
      document.querySelector("body").style.overflow = "auto";
    };
  }, []);

  async function fetchMyMarket() {
    const response = await api.market.fetchMarkets(marketType);
    const markets = response.data;

    setMarketString(
      markets
        .map((market: any) => {
          return market["shopId"];
        })
        .join("\n")
    );
  }

  async function submit(e: any) {
    e.preventDefault();
    try {
      setPending(true);

      const shopIdString = e.target.shopIdInput.value;

      const items = shopIdString.split("\n").map((item: string) => {
        return item.trim();
      });
      await api.market.createMarket(marketType, items);

      onUpdate();
    } catch (e) {
    } finally {
      setPending(false);
    }
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full h-screen max-h-full bg-black bg-opacity-70">
      <div className="bg-gray-50 max-w-xl w-full p-4 rounded shadow relative mx-auto top-10">
        <div>
          <h3 className="text-xl font-semibold text-gray-700">등록 마켓 관리</h3>
          <button
            type="button"
            className="absolute top-4 right-4 "
            onClick={() => {
              onClose();
            }}
          >
            <XCircleIcon color="black" className="w-8 h-8" />
          </button>
        </div>
        <div className="mt-4">
          <form onSubmit={submit} className="w-full flex flex-col align-center justify-center">
            <textarea
              id="shopIdInput"
              name="shopIdInput"
              placeholder={`${marketType} 스토어 아이디를 입력해주세요`}
              value={marketString}
              onChange={(e) => {
                setMarketString(e.target.value);
              }}
              className="w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
              style={{ height: 200 }}
            />
            <button
              type={pending ? "button" : "submit"}
              aria-disabled={pending}
              className="mt-1 flex h-10 whitespace-nowrap px-4 items-center justify-center rounded-md border text-sm transition-all focus:outline-none bg-blue-500 text-white"
            >
              수정
              {pending && (
                <svg
                  className="animate-spin ml-2 h-4 w-4 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z" />
                </svg>
              )}
              <span aria-live="polite" className="sr-only" role="status">
                {pending ? "Loading" : "Submit form"}
              </span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
