import { atom } from "recoil";
import { Account } from "../api/types/account";

const authState = atom<{
  waiting: boolean;
  account: Account | null;
}>({
  key: "authState",
  default: {
    waiting: true,
    account: null,
  },
});

export default authState;
