import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useRecoilState } from "recoil";
import api from "../api";
import AdminPlanEditPopup from "../components/Admin/UserPlanPopup";
import authState from "../recoil/auth";
import { useNavigate } from "react-router-dom";

type Props = {};

export default function Admin({}: Props) {
  const navigate = useNavigate();

  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [username, setUsername] = useState<string>();
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  async function fetchUsers(page: number) {
    try {
      setLoading(true);
      setPage(page);
      const response = await api.manager.fetchUsers(searchKeyword, page);
      const { content = [], totalPages, totalElements } = response.data;
      setItems(content);
      setTotalCount(totalElements);
      setPageCount(totalPages);
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }

  const renderList = () => {
    if (loading) {
      return <></>;
    }

    return (
      <>
        {items.map((item: any) => {
          return (
            <tr
              key={item.id}
              className="border hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                navigate("/admin/users/" + item.uid);
              }}
            >
              <td className="py-1 px-2 text-center">{item.id}</td>
              <td className="py-1 px-2 text-center">{item.uid}</td>
              <td className="py-1 px-2 text-left">{item.username}</td>
              <td className="py-1 px-2 text-center">{item.plan.plan.name}</td>
              <td className="py-1 px-2 text-center">{item.plan.expiresAt}</td>
            </tr>
          );
        })}
      </>
    );
  };

  const renderPagination = () => {
    if (loading || pageCount < 1) {
      return <></>;
    }

    return (
      <div className="my-4">
        <div className="flex gap-2 items-center justify-center mt-2 md:mt-0">
          {page >= 10 && (
            <ChevronDoubleLeftIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer text-blue-700"
              onClick={() => {
                let targetPage = page - 10;
                if (targetPage < 0) {
                  targetPage = 0;
                }
                setPage(targetPage);
              }}
            />
          )}
          <ReactPaginate
            containerClassName={"flex items-center justify-center gap-2 text-blue-700"}
            pageClassName={"px-2 py-1 text-2xs font-medium text-center rounded hover:bg-blue-100 cursor"}
            activeClassName={"bg-blue-500 text-white hover:bg-blue-500"}
            disabledLinkClassName="text-red"
            onPageChange={(e) => {
              setPage(e.selected);
            }}
            initialPage={page}
            pageCount={pageCount}
            breakLabel="..."
            previousLabel={
              <>
                <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
              </>
            }
            nextLabel={
              <>
                <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
              </>
            }
          />
          {pageCount > 10 && pageCount - page > 10 && (
            <ChevronDoubleRightIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer text-blue-700"
              onClick={() => {
                let targetPage = page + 10;
                if (targetPage > pageCount) {
                  targetPage = pageCount - 1;
                }
                setPage(targetPage);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSearchArea = () => {
    return (
      <div className="w-full text-right">
        <select name="" id="" className="border px-2 py-1">
          <option value="">UID</option>
        </select>
        <input
          type="text"
          className="ml-2 border px-2 py-1"
          placeholder="검색어를 입력해주세요"
          value={searchKeyword}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              fetchUsers(0);
            }
          }}
        />
        <button
          className="ml-2 bg-blue-500 px-2 py-1 text-white hover:bg-blue-600"
          onClick={() => {
            fetchUsers(0);
          }}
        >
          검색
        </button>
      </div>
    );
  };

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8">
        <div className="flex flex-col md:flex-row w-full h-full gap-4 w-full md:m-auto">
          <div className="w-full grow">
            {renderSearchArea()}
            <p>총: {totalCount}명</p>
            <table className="mt-4 min-w-full bg-white border">
              <thead className="bg-gray-600 text-white">
                <tr>
                  <th className="w-1/8 text-center py-1 px-4 font-semibold text-sm">ID</th>
                  <th className="w-1/8 text-center py-1 px-4 font-semibold text-sm">UID</th>
                  <th className="w-1/4 text-left py-1 px-4 font-semibold text-sm">이메일</th>
                  <th className="w-1/6 text-center py-1 px-4 font-semibold text-sm">현재 플랜</th>
                  <th className="w-1/6 text-center py-1 px-4 font-semibold text-sm">플랜 만료일</th>
                </tr>
              </thead>
              <tbody>{renderList()}</tbody>
            </table>
            {renderPagination()}
          </div>
        </div>
        {selectedItem && (
          <AdminPlanEditPopup
            item={selectedItem}
            onClose={() => {
              setSelectedItem(null);
              fetchUsers(page);
            }}
          />
        )}
      </div>
    </main>
  );
}
