import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import api from "../api";
import UserAuthPopup from "../components/Admin/UserAuthPopup";
import UserPlanPopup from "../components/Admin/UserPlanPopup";
import authState from "../recoil/auth";

type Props = {};

export default function AdminUser({}: Props) {
  const navigate = useNavigate();

  const [item, setItem] = useState<any>();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [editableAuths, setEditableAuths] = useState<any[]>([]);
  const [showAuthPopup, setShowAuthPopup] = useState(false);
  const [showPlanPopup, setShowPlanPopup] = useState(false);

  useEffect(() => {
    fetchAuth();
    fetchUser();
  }, [page]);

  async function fetchUser() {
    try {
      const uid = window.location.pathname.split("/")[3];
      setLoading(true);
      const response = await api.manager.fetchUser(uid);
      setItem(response.data);
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }

  const fetchAuth = () => {
    api.admin
      .fetchAuth()
      .then((res) => {
        setEditableAuths(res.data);
      })
      .catch(() => {
        setEditableAuths([]);
      });
  };

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8">
        <div className="bg-white p-4  bg-white rounded shadow">
          <div className="text-xl font-medium text-black">계정 정보</div>
          <p className="text-gray-500 mt-2">UID: {item?.uid}</p>
          <p className="text-gray-500 mt-2">이메일: {item?.username}</p>
          {editableAuths?.length > 0 && (
            <>
              <div className="mt-8 text-xl font-medium text-black">권한</div>
              {item?.authorities.map((info: any, index: number) => {
                return (
                  <p key={index} className="text-gray-500 mt-1 text-sm">
                    {info.name}
                  </p>
                );
              })}
              <button
                className="mt-2 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
                onClick={() => {
                  setShowAuthPopup(true);
                }}
              >
                권한 수정
              </button>
            </>
          )}
          <div className="mt-8 text-xl font-medium text-black">현재 플랜</div>
          <p className="text-gray-500 mt-2">플랜명: {item?.plan?.plan.name}</p>
          {item?.plan.expiresAt && <p className="text-gray-500 mt-1">만료일: {item?.plan?.expiresAt}</p>}
          <button
            className="mt-2 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600"
            onClick={() => {
              setShowPlanPopup(true);
            }}
          >
            플랜 설정
          </button>
          {showAuthPopup && (
            <UserAuthPopup
              user={item}
              editableAuths={editableAuths}
              onClose={() => {
                setShowAuthPopup(false);
                fetchUser();
              }}
            />
          )}
          {showPlanPopup && (
            <UserPlanPopup
              item={item}
              onClose={() => {
                setShowPlanPopup(false);
                fetchUser();
              }}
            />
          )}
        </div>
        <div className="mt-4">
          <button
            className="bg-gray-300 text-gray-600 px-4 py-1 rounded shadow-sm"
            onClick={() => {
              navigate(-1);
            }}
          >
            뒤로가기
          </button>
        </div>
      </div>
    </main>
  );
}
