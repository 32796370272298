import request from "../utils/Request";
import { Plan, PlanPackageItemRes } from "./types/plan";

const tagApi = {
  // 결제 시작
  buy: () => {
    return request.post(`/v1/plan/buy/2`);
  },
  // 결제 정보
  getInfo: () => {
    return request.get<Plan[]>("/v1/plan");
  },
  getPackages: () => {
    return request.get<PlanPackageItemRes[]>("/v1/plan/package");
  },
  // 내 결제 수단
  getMy: () => {
    return request.get("/v1/plan/my");
  },
};

export default tagApi;
