import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import api from "../api";
import authState from "../recoil/auth";
import { useEffect, useState } from "react";
import { USER_ROLES } from "../api/types/account";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showAdmin, setShowAdmin] = useState(false);
  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  useEffect(() => {
    fetchAccount();
  }, []);

  useEffect(() => {
    if (account) {
      setShowAdmin(account!.isAdminPageAccessible);
    }
  }, [account]);

  const fetchAccount = () => {
    api.account
      .fetch()
      .then((response) => {
        setAuthState({
          account: response.data,
          waiting: false,
        });
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        });
      });
  };

  async function logout() {
    await api.account.logout();
    setAuthState({ waiting: false, account: null });
    navigate("/login");
  }

  const renderPlanItem = () => {
    if (!account) {
      return;
    }

    if (account?.plan?.plan.id === 1) {
      return (
        <button
          className="text-2xs cursor-pointer py-1 px-2 rounded bg-green-600"
          onClick={() => {
            navigate("/plan");
          }}
        >
          플랜 구매하기
        </button>
      );
    } else if (account?.plan?.plan.id === 2) {
      const targetDate = new Date(account.plan.expiresAt);
      const currentDate = new Date();
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      return (
        <button
          className="text-2xs cursor-pointer py-1 px-2 rounded bg-green-600"
          onClick={() => {
            navigate("/plan");
          }}
        >
          Standard({daysDifference}일)
        </button>
      );
    }
  };

  return (
    <header className="bg-gray-800 text-white p-4 fixed top-0 left-0 w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-lg font-bold">
          <Link to="/">베스트 소싱</Link>
        </h1>
        <nav>
          <ul className="flex space-x-4">
            {showAdmin && (
              <li>
                <Link to="admin" className="hover:text-gray-200">
                  관리자
                </Link>
              </li>
            )}
            <li>{renderPlanItem()}</li>
            <li>
              <Link to="about" className="hover:text-gray-200">
                소개
              </Link>
            </li>
            <li>
              <a
                href="https://aop.notion.site/5e813349e9b2408fa92e4343e8d08906?pvs=4"
                className="hover:text-gray-200"
                target="_blank"
                rel="noreferrer"
              >
                사용법
              </a>
            </li>
            <li>
              <Link to="my" className="hover:text-gray-200">
                마이페이지
              </Link>
            </li>
            <li>
              <button className="hover:text-gray-200" onClick={logout}>
                로그아웃
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
