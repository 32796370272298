import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import api from "../api";
import { MarketType, Product } from "../api/types/market";
import UpdateMarketList from "../components/Home/UpdateMarketList";
import ManageModalPopup from "../components/ManageMarketPopup";
import Tabs from "../components/Tabs";
import { useRecoilState } from "recoil";
import authState from "../recoil/auth";

type Props = {
  message?: string;
};

export default function Home({ message }: Props) {
  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  const [showPopup, setShowPopup] = useState(false);
  const [marketType, setMarketType] = useState<MarketType>("naver");
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showUpdatedBanner, setShowUpdatedBanner] = useState(false);

  const updateMarketListRef = useRef();

  useEffect(() => {
    fetchBests(page, size);
  }, [page, marketType]);

  async function fetchBests(page: number, size: number) {
    try {
      setLoading(true);
      setPage(page);
      const response = await api.market.fetchBests(marketType, page, size);
      const { content = [], totalPages } = response.data;
      setItems(content);
      setPageCount(totalPages);
    } catch (e) {
      // do nothing
    } finally {
      setLoading(false);
    }
  }

  function extractCategories(category: any) {
    let names = [];
    while (category) {
      if (category.name) {
        names.push(category.name);
      }
      category = category.parent;
    }
    return names.reverse();
  }

  function getCategoryNameAtIndex(categoryNames: string[], index: number) {
    return categoryNames.length <= index ? null : categoryNames[index];
  }

  function parseHashtags(tagString: string) {
    if (!tagString) {
      return "";
    }
    const formattedItems = tagString.split(",").map((item: string) => "#" + item);
    const formattedText = formattedItems.join(", ");
    return formattedText;
  }

  async function crawl() {
    try {
      setShowUpdatedBanner(false);
      api.market.requestRefresh(marketType).then(() => {
        fetchBests(0, size);
      });
    } catch (e) {
      // do nothing
    } finally {
    }
  }

  async function download() {
    const response = await api.market.fetchBests(marketType, 0, 1000000);
    const { content = [] } = response.data;

    let data = content
      .map((item: Product) => {
        const cateogires = extractCategories(item.category);
        let url = "";
        if (marketType === "naver") {
          url = `https://smartstore.naver.com/${item.shop?.shopId}/products/${item.productId}`;
        } else {
          url = `https://www.11st.co.kr/products/${item.productNo}`;
        }

        return [
          item.productNo,
          item.shop?.shopId,
          `"${item.shop.shopName}"`,
          // "",
          getCategoryNameAtIndex(cateogires, 0),
          getCategoryNameAtIndex(cateogires, 1),
          getCategoryNameAtIndex(cateogires, 2),
          getCategoryNameAtIndex(cateogires, 3),
          `"${item.name}"`,
          url,
          item.salePrice,
          item.discountedSalePrice,
          item.totalReviewCount,
          item.averageReviewScore,
          item.stockQuantity,
          // "",
          `"${parseHashtags(item.sellerTags)}"`,
          item.representativeImageUrl,
        ].join(",");
      })
      .join("\n");

    // 배열 데이터를 CSV 문자열로 변환
    const title = [
      "No",
      "마켓ID(SHOP)",
      "마켓명",
      // "판매자등급",
      "대분류",
      "중분류",
      "소분류",
      "세분류",
      "상품명",
      "상품 URL",
      "판매가",
      "할인가",
      "리뷰수",
      "평점",
      "재고",
      // "판매유형",
      "태그",
      "썸네일URL",
    ].join(",");

    // Blob을 사용해 파일로 변환
    const blob = new Blob(["\ufeff" + title + "\n" + data], { type: "text/csv;charset=utf-8;" });

    // 현재 날짜와 시간을 가져옴
    const now = new Date();
    const filename = `beso_${now.getFullYear()}-${
      now.getMonth() + 1
    }-${now.getDate()}_${now.getHours()}-${now.getMinutes()}-${now.getSeconds()}.csv`;

    // 다운로드 링크 생성 및 실행
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();

    // 메모리 해제
    URL.revokeObjectURL(link.href);
  }

  const clickProductLink = (item: any) => {
    let url = "";
    if (marketType === "naver") {
      url = `https://smartstore.naver.com/${item.shop?.shopId}/products/${item.productId}`;
    } else {
      url = `https://www.11st.co.kr/products/${item.productNo}`;
    }

    window.open(url);
  };

  const renderList = () => {
    if (loading) {
      return <></>;
    }

    if (items?.length === 0) {
      return <div className="border border-gray-300 p-4 bg-white">베스트 상품이 없습니다</div>;
    }

    return (
      <ul className="flex flex-col gap-2">
        {items.map((item: any) => {
          return (
            <li
              className="flex gap-4 md:gap-6 shadow bg-white cursor-pointer min-h-30 overflow w-full"
              key={item.productNo}
              onClick={() => {
                clickProductLink(item);
              }}
            >
              <div className="relative flex flex-col" style={{ width: 120, minWidth: 120 }}>
                <span
                  onClick={() => {
                    clickProductLink(item);
                  }}
                >
                  <img
                    src={item.representativeImageUrl}
                    className="w-full h-20 grow object-cover"
                    alt={item.name}
                    style={{ height: 120 }}
                  />
                </span>
                <div className="pl-2 py-1 text-2xs text-gray-500 font-medium text-center">{item.shop?.shopName}</div>
              </div>
              <div className="flex flex-col justify-center py-2 pr-2 overflow-hidden">
                <p className="tex-base font-semibold truncate">{item.name}</p>
                <p className="text-base">
                  {item.discountedSalePrice && (
                    <span className="mr-2 text-sm text-red-600 font-semibold">
                      {item.discountedSalePrice?.toLocaleString()}
                      <span>원</span>
                    </span>
                  )}
                  {item.discountedSalePrice && item.salePrice && (
                    <span className="text-sm text-gray-400 line-through">{item.salePrice?.toLocaleString()}</span>
                  )}
                  {!item.discountedSalePrice && item.salePrice && (
                    <span className="mr-2 text-sm text-red-600 font-semibold">
                      {item.salePrice?.toLocaleString()}
                      <span>원</span>
                    </span>
                  )}
                </p>
                <p className="mt-1 text-sm text-gray-600 truncate text-2xs">
                  {extractCategories(item.category).join(" > ")}
                </p>
                {item.averageReviewScore && item.totalReviewCount && (
                  <p className="mt-1 text-sm text-2xs text-gray-600">
                    <span>리뷰: </span>
                    <span className="text-green-600">{item.averageReviewScore}</span>(
                    <span className="text-green-600">{item.totalReviewCount?.toLocaleString()}</span>)
                  </p>
                )}
                <p className="mt-1 text-sm text-gray-600 truncate text-2xs">{parseHashtags(item.sellerTags)}</p>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderPagination = () => {
    if (loading || pageCount < 1) {
      return <></>;
    }

    return (
      <div className="my-4">
        <div className="md:float-left">
          <span className="mr-1">페이지당 개수:</span>
          <select
            value={size}
            className="py-1 px-2"
            onChange={(e: any) => {
              setSize(e.target.value);
              fetchBests(0, e.target.value);
            }}
          >
            <option value="10">10개</option>
            <option value="20">20개</option>
            <option value="50">50개</option>
            <option value="100">100개</option>
          </select>
        </div>
        <div className="flex gap-2 items-center justify-center mt-2 md:mt-0">
          {page >= 10 && (
            <ChevronDoubleLeftIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer text-blue-700"
              onClick={() => {
                let targetPage = page - 10;
                if (targetPage < 0) {
                  targetPage = 0;
                }
                setPage(targetPage);
              }}
            />
          )}
          <ReactPaginate
            containerClassName={"flex items-center justify-center gap-2 text-blue-700"}
            pageClassName={"px-2 py-1 text-2xs font-medium text-center rounded hover:bg-blue-100 cursor"}
            activeClassName={"bg-blue-500 text-white hover:bg-blue-500"}
            disabledLinkClassName="text-red"
            onPageChange={(e) => {
              setPage(e.selected);
            }}
            initialPage={page}
            pageCount={pageCount}
            breakLabel="..."
            previousLabel={
              <>
                <ChevronLeftIcon strokeWidth={2} className="h-4 w-4" />
              </>
            }
            nextLabel={
              <>
                <ChevronRightIcon strokeWidth={2} className="h-4 w-4" />
              </>
            }
          />
          {pageCount > 10 && pageCount - page > 10 && (
            <ChevronDoubleRightIcon
              strokeWidth={2}
              className="h-4 w-4 cursor-pointer text-blue-700"
              onClick={() => {
                let targetPage = page + 10;
                if (targetPage > pageCount) {
                  targetPage = pageCount - 1;
                }
                setPage(targetPage);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8">
        <div className="flex flex-col md:flex-row w-full h-full gap-4 w-full md:m-auto">
          <div className="w-full grow">
            <Tabs
              onSelect={(index) => {
                setPage(0);
                setMarketType(index === 0 ? "naver" : "11st");
              }}
              titles={["네이버", "11번가"]}
            />
            <div className="mt-4 mb-4 flex justify-between">
              <div>
                <button
                  className="px-4 py-2 border rounded border-blue-500 text-blue-500 hover:bg-gray-200"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                >
                  등록 마켓 관리
                </button>

                <button
                  className="ml-2 px-4 py-2 border rounded border-blue-500 text-blue-500 hover:bg-gray-200"
                  onClick={crawl}
                >
                  수집하기
                </button>
              </div>
              {account && account?.isCsvExport && (
                <button
                  className="px-4 py-2 border rounded border-blue-500 text-blue-500 hover:bg-gray-200"
                  onClick={download}
                >
                  다운로드
                </button>
              )}
            </div>
            {showUpdatedBanner && (
              <div className="mb-4 text-2xs text-white bg-blue-500 py-1 px-2 text-center">업데이트 완료</div>
            )}
            {!loading && (
              <UpdateMarketList
                marketType={marketType}
                onRefresh={() => {
                  setShowUpdatedBanner(true);
                  fetchBests(0, size);
                }}
              />
            )}
            {renderList()}
            {renderPagination()}
          </div>
        </div>
      </div>
      {showPopup && (
        <ManageModalPopup
          marketType={marketType}
          onUpdate={() => {
            crawl();
            setShowPopup(false);
          }}
          onClose={() => {
            setShowPopup(false);
          }}
        />
      )}
    </main>
  );
}
