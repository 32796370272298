import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { PlanRes } from "../api/types/plan";
import authState from "../recoil/auth";
import PasswordChangePopup from "../components/PasswordChangePopup";

export default function MyPage({}) {
  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  const [planRes, setPlanRes] = useState<PlanRes>();
  const [passwordEditMode, setPasswordEditMode] = useState(false);

  useEffect(() => {
    setPlanRes(account?.plan);
  }, []);

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto md:my-8">
        <div className="bg-white p-4  bg-white rounded shadow">
          <div className="text-xl font-medium text-black">이메일</div>
          <p className="text-gray-500 mt-1">{account?.username}</p>
          <div className="mt-8 text-xl font-medium text-black">내 코드</div>
          <p className="text-gray-500 mt-1">{account?.uid}</p>
          <div className="mt-8 text-xl font-medium text-black">현재 플랜</div>
          <p className="text-gray-500 mt-1">플랜명: {planRes?.plan.name}</p>
          {planRes?.expiresAt && <p className="text-gray-500 mt-1">만료일: {planRes?.expiresAt}</p>}

          <div className="mt-8 text-xl font-medium text-black">비밀번호</div>
          <button
            className="mt-2 bg-gray-200 text-gray-600 px-2 py-1 rounded hover:bg-gray-300"
            onClick={() => {
              setPasswordEditMode(true);
            }}
          >
            비밀번호 수정
          </button>
          {passwordEditMode && (
            <PasswordChangePopup
              onClose={() => {
                setPasswordEditMode(false);
              }}
            />
          )}
        </div>
      </div>
    </main>
  );
}
