import { useState } from "react";

type Props = {
  titles: string[];
  onSelect: (index: number) => void;
};

export default function Tabs({ titles, onSelect }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getClassName = (index: number) => {
    if (index === selectedIndex) {
      return "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500";
    } else
      return "inline-block p-4 border-b-2 rounded-t-lg hover:text-blue-500 dark:hover:text-blue-500 cursor-pointer";
  };

  return (
    <div className="mb-4 border-b border-gray-300">
      <ul className="flex flex-wrap -mb-px">
        {titles.map((title, index) => {
          return (
            <li
              key={index}
              className={getClassName(index)}
              onClick={() => {
                setSelectedIndex(index);
                onSelect(index);
              }}
            >
              {title}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
