import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import api from "../../api";
import { MarketType } from "../../api/types/market";
import authState from "../../recoil/auth";

type Props = {
  marketType: MarketType;
  onRefresh: () => void;
};

const UpdateMarketList = ({ marketType, onRefresh }: Props) => {
  const [waitingMarkets, setWaitingMarkets] = useState([]);
  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const hasUpdatemarketsRef = useRef(false);

  useEffect(() => {
    setUpdateInterval();

    return () => {
      clearInterval(interval.current!);
    };
  }, [account, marketType]);

  const setUpdateInterval = () => {
    clearInterval(interval.current!);
    interval.current = setInterval(() => {
      fetchMyMarket();
    }, 3 * 1000);

    fetchMyMarket();
  };

  async function fetchMyMarket() {
    if (!account) {
      return;
    }

    api.market
      .fetchMarkets(marketType)
      .then((response) => {
        const markets = response.data;

        const waitingMarkets = markets.filter((market: any) => market["isUpdating"] === true);
        setWaitingMarkets(waitingMarkets);

        if (waitingMarkets.length === 0) {
          clearTimeout(interval.current!);
        }

        if (waitingMarkets.length > 0) {
          hasUpdatemarketsRef.current = true;
        }

        if (hasUpdatemarketsRef.current && waitingMarkets.length === 0) {
          hasUpdatemarketsRef.current = false;
          onRefresh();
        }
      })
      .catch((e) => {
        // do nothing
      });
  }

  if (waitingMarkets.length === 0) {
    return <></>;
  }

  return (
    <div className="mb-4 text-2xs text-white bg-blue-500 py-1 px-2 text-center">
      {waitingMarkets
        .map((market: any) => {
          return market["shopName"] ? market["shopName"] : market["shopId"];
        })
        .join(", ")}{" "}
      업데이트 중..
    </div>
  );
};

export default UpdateMarketList;
