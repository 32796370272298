import { XCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import api from "../../api";
import { PlanPackageItemRes } from "../../api/types/plan";

type Props = {
  onClose: () => void;
  item: any;
};

export default function UserPlanPopup({ onClose, item }: Props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [packages, setPackages] = useState<PlanPackageItemRes[]>();
  const [selectedPackage, setSelectedPackage] = useState<PlanPackageItemRes>();
  const [planId, setPlanId] = useState(1);
  const [days, setDays] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    fetchPackages();
  }, []);

  const fetchPackages = () => {
    api.plan
      .getPackages()
      .then((res) => {
        setPackages(res.data);
      })
      .catch(() => {
        setErrorMessage("현재 패키지를 불러올 수 없습니다");
      });
  };

  const onSubmit = () => {
    api.manager
      .updateUserPlan(item.id, planId, days, price)
      .then(() => {
        onClose();
      })
      .catch(() => {
        setErrorMessage("네트워크 에러");
      });
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50  w-full h-screen max-h-full bg-black bg-opacity-70">
      <div className="bg-gray-50 max-w-sm w-full p-4 rounded shadow relative mx-auto top-10">
        <div>
          <h3 className="text-xl font-semibold text-gray-700">플랜 설정</h3>
          <button
            type="button"
            className="absolute top-4 right-4 "
            onClick={() => {
              onClose();
            }}
          >
            <XCircleIcon color="black" className="w-8 h-8" />
          </button>
        </div>
        <div className="mt-4">
          <div>플랜 패키지 선택</div>
          <div className="mt-1">
            {packages?.map((item) => {
              return (
                <button
                  className="bg-green-500 hover:bg-green-600 text-white px-2 py-1 rounded shadow m-1"
                  onClick={() => {
                    setPlanId(item.plan.id);
                    setDays(item.days);
                    setPrice(item.price);
                    //setSelectedPackage(item);
                  }}
                >
                  {item.name}
                </button>
              );
            })}
          </div>
          <hr className="my-2" />
          <div className="w-full flex flex-col align-center justify-center">
            <label className="text-xs text-gray-600">플랜명</label>
            <select
              className="mt-1 w-full rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none sm:text-sm"
              name="planId"
              value={planId}
              onChange={(e) => {
                setPlanId(parseInt(e.target.value));
              }}
            >
              <option value="1">Free</option>
              <option value="2">Standard</option>
            </select>
            <label className="mt-3 text-xs text-gray-600">추가일자</label>
            <input
              name="days"
              type="number"
              required
              value={days}
              onChange={(e) => {
                setDays(parseInt(e.target.value));
              }}
              className="mt-1 w-full appearance-none rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
            />
            <label className="mt-3 text-xs text-gray-600">가격</label>
            <input
              name="price"
              type="number"
              required
              value={price}
              onChange={(e) => {
                setPrice(parseInt(e.target.value));
              }}
              className="mt-1 w-full appearance-none rounded-md border border-gray-300 px-2 py-1 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-sm"
            />
          </div>
          <div>{errorMessage && <p className="mt-2 flex gap-1 text-xs text-red-500">{errorMessage}</p>}</div>
          <div className="mt-4 gap-1 text-center flex justify-center">
            <button className="bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600 text-xs" onClick={onSubmit}>
              확인
            </button>
            <button
              className="bg-gray-300 text-gray-600 px-4 py-1 rounded hover:bg-gray-400 text-xs"
              onClick={() => {
                onClose();
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
