import { useEffect, useRef, useState } from "react";
import api from "../api";
import { Plan, PlanRes } from "../api/types/plan";
import { useRecoilState } from "recoil";
import authState from "../recoil/auth";

export default function PlanPage({}) {
  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  const [planRes, setPlanRes] = useState<PlanRes>();
  const [showPopup, setShowPopup] = useState(false);
  const [freePlanInfo, setFreePlanInfo] = useState<Plan>();
  const [stardPlanInfo, setStadardPlanInfo] = useState<Plan>();
  const [paymentText, setPaymentText] = useState("");

  const orderIdRef = useRef<string>("");
  const orderNameRef = useRef("");
  const customerNameRef = useRef("");
  const customerEmailRef = useRef("");

  const fetchMyPayment = async () => {
    setPlanRes(account?.plan);
  };

  const fetchPaymentsText = () => {
    api.text.fetch("PAYMENTS").then((res) => {
      setPaymentText(res.data.text);
    });
  };

  const fetchInfo = async () => {
    api.plan.getInfo().then((res) => {
      if (res.data && res.data.length > 1) {
        setFreePlanInfo(res.data[0]);
        setStadardPlanInfo(res.data[1]);
      }
    });
  };

  const initPayment = () => {
    api.plan
      .buy()
      .then((res) => {
        const { clientKey, customerKey, orderId, orderName, customerName, customerEmail, amount } = res.data;

        const paymentWidget = (window as any).PaymentWidget(clientKey, customerKey);

        orderIdRef.current = orderId;
        orderNameRef.current = orderName;
        customerNameRef.current = customerName;
        customerEmailRef.current = customerEmail;

        paymentWidget.renderPaymentMethods("#payment-method", { value: amount }, { variantKey: "DEFAULT" });

        (window as any).paymentWidget = paymentWidget;
      })
      .catch((e) => {
        alert("네트워크 에러");
      });
  };

  const startPayment = () => {
    let customSuccessUrl = `https://${window.location.host}/payment/complete`;
    let customFailUrl = `https://${window.location.host}/payment/fail`;

    (window as any).paymentWidget.requestPayment({
      orderId: orderIdRef.current,
      orderName: orderNameRef.current,
      successUrl: customSuccessUrl,
      failUrl: customFailUrl,
      customerEmail: customerEmailRef.current,
      customerName: customerNameRef.current,
    });
  };

  const clickShowPopup = () => {
    setShowPopup(true);
    // initPayment();
  };

  const renderPopup = () => {
    if (!showPopup) {
      return <></>;
    }

    return (
      <div id="modal" className="fixed inset-0 bg-black bg-opacity-50 justify-center items-center pt-16">
        <div className="bg-white m-8 p-8 rounded shadow-lg md:container mx-auto" style={{ maxWidth: 800 }}>
          <div id="payment-method" style={{ whiteSpace: "pre" }}>
            {paymentText}
          </div>
          <div className="mt-2 text-center flex gap-2 justify-center">
            {/* <button
              className="mt-4 py-2 px-4 bg-blue-500 text-white font-semibold rounded hover:bg-blue-700"
              onClick={startPayment}
            >
              결제하기
            </button> */}
            <button
              id="closeModal"
              className="mt-4 px-4 py-2 bg-gray-200 text-gray-600 rounded hover:bg-gray-300"
              onClick={() => {
                setShowPopup(false);
              }}
            >
              닫기
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    fetchMyPayment();
    fetchInfo();
    fetchPaymentsText();
  }, []);

  return (
    <main className="flex bg-gray-100 pt-16 px-2">
      <div className="container mx-auto my-2 md:my-8">
        <div className="bg-white p-4 md:p-8 bg-white rounded shadow">
          <div></div>
          <div className="grid md:grid-cols-3">
            <div>
              <p className="font-medium text-black" style={{ fontSize: 24 }}>
                이용플랜
              </p>
            </div>
            <div className="mt-4 md:mt-0">
              <span className="text-2xs text-yellow-600 bg-yellow-200 py-1 px-2 rounded font-medium">FREE</span>
              <p className="mt-1 text-yellow-500">
                <span className="font-medium" style={{ fontSize: 30, marginRight: 2 }}>
                  {freePlanInfo && new Intl.NumberFormat().format(freePlanInfo!.price)}
                </span>
                원
              </p>
              <p className="mt-2 text-gray-600 pr-4" style={{ fontSize: 15, whiteSpace: "pre-line" }}>
                {freePlanInfo?.comment}
              </p>
            </div>
            <div className="mt-4 md:mt-0">
              <span className="text-2xs text-blue-600 bg-blue-200 py-1 px-2 rounded font-medium">STANDARD</span>
              <p className="mt-1">
                <span className="font-medium text-blue-600" style={{ fontSize: 30, marginRight: 2 }}>
                  {stardPlanInfo && new Intl.NumberFormat().format(stardPlanInfo!.price)}
                </span>
                <span className="text-blue-600">원</span>
                <span className="text-gray-600"> / 30일</span>
              </p>
              <p className="mt-2 text-gray-600 pr-4" style={{ fontSize: 15, whiteSpace: "pre-line" }}>
                {stardPlanInfo?.comment}
              </p>
            </div>
          </div>
          <hr className="my-8" />
          <p className="font-medium text-gray-700 mb-2">최대 스토어 분석 개수</p>
          <table className="mt-2 w-full">
            <tbody>
              <tr>
                <th className="text-left text-gray-500 font-medium py-2" style={{ width: "34%" }}>
                  네이버스토어
                </th>
                <td style={{ width: "33%" }}>
                  <span className="font-medium" style={{ fontSize: 20, marginRight: 2 }}>
                    {freePlanInfo?.maxNaverShop}
                  </span>
                </td>
                <td style={{ width: "33%" }}>
                  <span className="font-medium" style={{ fontSize: 20, marginRight: 2 }}>
                    {stardPlanInfo?.maxNaverShop}
                  </span>
                </td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 font-medium py-2">11번가</th>
                <td>
                  <span className="font-medium" style={{ fontSize: 20, marginRight: 2 }}>
                    {freePlanInfo?.max11StShop}
                  </span>
                </td>
                <td>
                  <span className="font-medium" style={{ fontSize: 20, marginRight: 2 }}>
                    {stardPlanInfo?.max11StShop}
                  </span>
                </td>
              </tr>
              <tr>
                <th className="text-left text-gray-500 font-medium py-2">데이터 내보내기</th>
                <td>
                  <span className="font-medium text-red-500" style={{ fontSize: 20, marginRight: 2 }}>
                    {freePlanInfo?.isCsvExport ? "O" : "X"}
                  </span>
                </td>
                <td>
                  <span className="font-medium text-blue-500" style={{ fontSize: 20, marginRight: 2 }}>
                    {stardPlanInfo?.isCsvExport ? "O" : "X"}
                  </span>
                </td>
              </tr>
              <tr>
                <th></th>
                <td></td>
                <td>
                  <button
                    className="mt-4 py-2 px-4 bg-blue-500 text-white font-semibold rounded hover:bg-blue-700"
                    onClick={clickShowPopup}
                    style={{ wordBreak: "keep-all" }}
                  >
                    Standard 30일 결제
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="mt-8 text-xs text-right">
            <a
              className="text-gray-600 hover:text-gray-900"
              href="https://aop.notion.site/aeba51d0a64d4ae89625389bde6503f1?pvs=4"
              target="_blank"
              rel="noreferrer"
            >
              환불정책 보기 ＞
            </a>
          </p>
        </div>
      </div>
      {renderPopup()}
    </main>
  );
}
