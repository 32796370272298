import { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import AppRoutes from "./components/AppRoutes";

function App() {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    return (
      <BrowserRouter>
        <CookiesProvider>
          <RecoilRoot>
            <AppRoutes />
          </RecoilRoot>
        </CookiesProvider>
      </BrowserRouter>
    );
  } else {
    return (
      <CookiesProvider>
        <RecoilRoot>
          <AppRoutes />
        </RecoilRoot>
      </CookiesProvider>
    );
  }
}

export default App;
