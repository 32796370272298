import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../api";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [aboutData, setAboutData] = useState<string>("");

  useEffect(() => {
    fetchAbout();
  }, []);

  if (location.pathname === "/login") {
    return null;
  }

  async function fetchAbout() {
    api.text.fetch("FOOTER").then((res) => {
      setAboutData(res.data?.text);
    });
  }

  return (
    <footer className="bg-gray-700 text-white p-4">
      {aboutData && (
        <div className="container mx-auto text-center" style={{ whiteSpace: "pre" }}>
          {aboutData}
        </div>
      )}
    </footer>
  );
};

export default Header;
