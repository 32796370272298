import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import api from "../api";
import authState from "../recoil/auth";

import MainLayout from "../components/MainLayout";
import Home from "../pages/Home";
import Waiting from "../pages/Waiting";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import My from "../pages/My";
import PaymentComplete from "../pages/PaymentComplete";
import PaymentFail from "../pages/PaymentFail";
import Plan from "../pages/Plan";
import About from "../pages/About";
import Admin from "../pages/Admin";
import AdminUser from "../pages/AdminUser";

export default function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [{ account, waiting }, setAuthState] = useRecoilState(authState);

  useEffect(() => {
    api.account
      .fetch()
      .then((response) => {
        setAuthState({
          account: response.data,
          waiting: false,
        });
      })
      .catch((error) => {
        setAuthState({
          account: null,
          waiting: false,
        });
        navigate("/login");
      });
  }, []);

  const renderAdmin = () => {
    if (account && account?.isAdminPageAccessible) {
      return (
        <>
          <Route path="admin" element={<Admin />} />
          <Route path="admin/users/:uid" element={<AdminUser />} />
        </>
      );
    }

    return <></>;
  };

  if (waiting) {
    return <Waiting message="인증정보를 불러오는 중" />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="my" element={<My />} />
          <Route path="plan" element={<Plan />} />
          <Route path="payment/complete" element={<PaymentComplete />} />
          <Route path="payment/fail" element={<PaymentFail />} />
          {renderAdmin()}
        </Route>
        {account === null ? <Route index element={<Navigate replace to="/login" />} /> : <></>}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}
