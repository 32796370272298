import axios from "axios";

let data = {
  // baseURL: '/v1',
  timeout: 10000,
  withCredentials: true,
};

// function getCookie(name: string) {
//   const value = `; ${document.cookie}`
//   const parts = value.split(`; ${name}=`)
//   if (parts.length === 2) return parts.pop().split(';').shift()
// }

// if (getCookie('SESSION')) {
//   localStorage.setItem('BINDUS_SESSION', getCookie('SESSION'))
// }

// if (localStorage.getItem('BINDUS_SESSION')) {
//   document.cookie = 'SESSION=' + localStorage.getItem('BINDUS_SESSION')
// }

const instance = axios.create(data);

// instance.interceptors.response.use(
//   (response) => {
//     const res = response.data

//     if (response.status !== 200) {
//       // Message({
//       //   message: res.error || 'Error?',
//       //   type: 'error',
//       //   duration: 5 * 1000,
//       // })

//       return Promise.reject(new Error(res.error || 'Error'))
//     } else {
//       return res
//     }
//   },
//   (error) => {
//     // Message({/
//     //   message: error.response.data.error ? error.response.data.error : error,
//     //   type: 'error',
//     //   duration: 5 * 1000,
//     // })
//     return Promise.reject(error)
//   }
// )

export default instance;
